import React from 'react'
import { Text, TouchableOpacity, StyleSheet } from 'react-native'
import { useNavigation } from '@react-navigation/native'

export default ({ swiperRef }) => {
  const navigation = useNavigation()

  return (
    <TouchableOpacity
      onPress={() => {
        swiperRef.current.goTo(0)
        navigation.navigate('Home')
      }}
    >
      <Text style={styles.logo}>ghrutam</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  logo: {
    fontSize: 48,
    fontWeight: 200
  }
})
