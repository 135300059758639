import React from 'react'
import { Text, View, TouchableOpacity, Linking, StyleSheet } from 'react-native'

export default () => {
  const paymentLink = 'https://buy.stripe.com/3csdS93j2auH6Sk3cc'

  return (
    <TouchableOpacity onPress={() => Linking.openURL(paymentLink)}>
      <Text style={styles.link}>buy now</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  link: {
    marginTop: 21,
    padding: 7,
    fontSize: 24,
    color: 'blue'
  }
})
