import React from 'react'
import { Text, View, TouchableOpacity, Linking, StyleSheet } from 'react-native'

export default () => {
  return (
    <TouchableOpacity onPress={() => Linking.openURL('mailto:support@ghrutam.com')}>
      <Text style={styles.link}>contact</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  link: {
    padding: 7,
    fontSize: 24,
    fontWeight: 100,
    color: 'blue'
  }
})
