import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import Home from './Home'

const NavigationStack = createStackNavigator()

const routes = {
  config: {
    screens: {
      Home: ''
    }
  }
}

export default ({ currentUser }) => {
  return (
    <NavigationContainer linking={routes}>
      <NavigationStack.Navigator screenOptions={{ headerShown: false, title: 'Ghrutam' }}>
        <NavigationStack.Screen name="Home" component={Home} />
      </NavigationStack.Navigator>
    </NavigationContainer>
  )
}
