import React, { useRef } from 'react'
import { View, Text, Image, ImageBackground, TouchableOpacity, Linking, Dimensions, StyleSheet } from 'react-native'
import Swiper from 'react-native-web-swiper'
import Header from './Header'
import PaymentLink from './PaymentLink'

const isSmallDevice = Dimensions.get('window').width < 768

export default ({ navigation }) => {
  const swiperRef = useRef(null)

  return (
    <View style={styles.container}>
      <Header swiperRef={swiperRef} />
      <Swiper
        ref={swiperRef}
        loop
        vertical
        minDistanceForAction={0.1}
        positionFixed
        controlsProps={{
          dotsTouchable: true,
          prevPos: false,
          nextPos: false
        }}
      >
        <View style={styles.slide}>
          <View style={styles.row}>
            <Image
              source={'/jar.jpg'}
              style={styles.image}
              resizeMode={'contain'}
            >
            </Image>
            <View style={styles.caption}>
              <Text style={styles.h1}>ghrutam is ghee</Text>
              <Text style={styles.text}>a tasty, healthy, classic food</Text>
              <Text style={styles.text}>made from grassfed, cultured butter</Text>
              <PaymentLink />
            </View>
          </View>
        </View>
        <View style={styles.slide}>
          <View style={styles.row}>
            <View style={styles.caption}>
              <Text style={styles.h1}>nutty, buttery taste</Text>
              <Text style={styles.text}>great on its own</Text>
              <Text style={styles.text}>enhances all other tastes</Text>
              <PaymentLink />
            </View>
            <Image
              source={'/photo.jpg'}
              style={styles.image}
              resizeMode={'contain'}
            />
          </View>
        </View>
        <View style={styles.slide}>
          <View style={styles.row}>
            <View style={styles.caption}>
              <Text style={styles.h1}>fuel your mind and body</Text>
              <Text style={styles.text}>for all seasons and body types</Text>
              <Text style={styles.text}>helps digestion, nutrient absorption</Text>
              <PaymentLink />
            </View>
            <Image
              source={'/spoon.jpg'}
              style={styles.image}
              resizeMode={'contain'}
            />
          </View>
        </View>
        <View style={styles.slide}>
          <View style={styles.row}>
            <Image
              source={'/cows.jpg'}
              style={styles.image}
              resizeMode={'contain'}
            />
            <View style={styles.caption}>
              <Text style={styles.h1}>cows nurture us</Text>
              <Text style={styles.text}>and our land</Text>
              <Text style={styles.text}>help us support them</Text>
              <PaymentLink />
            </View>
          </View>
        </View>
      </Swiper>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },
  container: {
    flex: 1,
    backgroundColor: 'white'
  },
  slide: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    height: '70vh',
    width: '40vw'
  },
  caption: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5
  },
  h1: {
    margin: 21,
    fontSize: 64,
    fontWeight: 100,
    textAlign: 'center'
  },
  h2: {
    fontSize: 32,
    fontWeight: 100,
    textAlign: 'center'
  },
  text: {
    fontSize: 24,
    fontWeight: 100,
    textAlign: 'center'
  },
  ...isSmallDevice && {
    row: {
      flexDirection: 'column',
      alignItems: 'center'
    },
    image: {
      height: '20vh',
      width: '40vw'
    },
    h1: {
      margin: 21,
      fontSize: 32,
      fontWeight: 100,
      textAlign: 'center'
    },
    h2: {
      fontSize: 24,
      fontWeight: 100,
      textAlign: 'center'
    },
    text: {
      fontSize: 18,
      fontWeight: 100,
      textAlign: 'center'
    }
  }
})
