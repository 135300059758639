import React from 'react'

import { AppRegistry, View } from 'react-native'

import Navigation from './Navigation'

const App = () => {
  return (
    <Navigation />
  )
}

AppRegistry.registerComponent('Ghrutam', () => App)
AppRegistry.runApplication('Ghrutam', {
  rootTag: document.getElementById('root')
})
