import React from 'react'
import { Text, View, TouchableOpacity, Linking, StyleSheet } from 'react-native'

export default () => {
  const subscriptionLink = 'https://buy.stripe.com/bIYg0hdXG1Yb3G8145'

  return (
    <TouchableOpacity onPress={() => Linking.openURL(subscriptionLink)}>
      <Text style={styles.link}>subscribe</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  link: {
    padding: 7,
    fontSize: 24,
    color: 'blue'
  }
})
