import React from 'react'
import { View, Text, TouchableOpacity, Linking, Dimensions, StyleSheet } from 'react-native'
import { useNavigationState } from '@react-navigation/native'
import Logo from './Logo'
import ContactLink from './ContactLink'
import SubscribeLink from './SubscribeLink'

export default ({ swiperRef }) => {
  const isSmallDevice = Dimensions.get('window').width < 768

  return (
    <View style={styles.container}>
      <Logo swiperRef={swiperRef} />
      {!isSmallDevice &&
       <View style={styles.links}>
         <TouchableOpacity onPress={() => swiperRef.current.goTo(0)}>
           <Text style={styles.link}>about</Text>
         </TouchableOpacity>
         <TouchableOpacity onPress={() => swiperRef.current.goTo(1)}>
           <Text style={styles.link}>taste</Text>
         </TouchableOpacity>
         <TouchableOpacity onPress={() => swiperRef.current.goTo(2)}>
           <Text style={styles.link}>health</Text>
         </TouchableOpacity>
         <TouchableOpacity onPress={() => swiperRef.current.goTo(3)}>
           <Text style={styles.link}>cause</Text>
         </TouchableOpacity>
         <ContactLink />
         <SubscribeLink />
       </View>}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 21,
    flexDirection: 'row'
  },
  links: {
    marginLeft: 42,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end'
  },
  link: {
    marginLeft: 7,
    marginRight: 7,
    fontSize: 24,
    fontWeight: 100,
    color: 'blue'
  }
})
